/* ==================================== */
/* ℹ️ main DIV container */
/* ==================================== */
.work-main-container {
  height: 750px;
  width: 100vw;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  background-color: #000;
}

.work-positioning-container {
  height: 100%;
  width: 100%;
  max-width: 123rem;
  margin: 0 auto;
  position: relative;
}

/* ==================================== */
/* ℹ️ parallax main container div*/
/* ==================================== */
.work-parallax-container {
  height: 100%;
  width: 100%;
}

/* ==================================== */
/* ℹ️ parallax banner*/
/* ==================================== */
.work-pb-container {
  height: 100%;
  width: 100%;
  max-width: 123rem;
  margin: 0 auto;
  position: relative;
}

.work-left-line-divider {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  border-left: 1px solid rgba(355, 355, 355, 0.4);
}

.projects-main-container {
  height: 100%;
  width: 100%;
  padding: 0 0 0 10%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
}

.projects-header h1 {
  margin-left: 40px;
  font-size: 6.5rem;
  font-family: "Archivo Black", sans-serif;
  color: rgb(145, 145, 145);
  position: relative;
  z-index: 2;
}

.projects-container {
  width: 80%;
  margin-top: -100px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.project {
  width: 350px;
  height: 400px;
}

.project-image {
  width: 99%;
  height: 200px;
  position: relative;
  z-index: 1;
  background-size: contain;
  background-repeat: no-repeat;
}
.project-1 {
  background-image: url(../images/work/brewlist.png);
}
.project-2 {
  background-image: url(../images/work/wip.png);
}
.project-3 {
  background-image: url(../images/work/wip.png);
}
/* ==================================== */
/* ℹ️ overlay for the project images */
/* ==================================== */
.project-image-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.project-image:hover .project-image-overlay {
  opacity: 1;
}
.project-image-text {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  z-index: 3;
}
.project-image:hover .project-image-text {
  top: 50%;
  left: 50%;
  opacity: 1;
}
.project-image-text h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}
.fadeIn-left {
  left: 20%;
}
.project-image-text h3 a {
  text-decoration: none;
  color: #fff;
}

/* ℹ️ℹ️ ---------- Media Querys ---------- ℹ️ℹ️ */

/* 🖥️ Small devices (portrait tablets and large phones, 300px and up) */
@media only screen and (min-width: 300px) and (max-width: 600px) {
  .work-main-container {
    height: 1200px;
  }
  .project {
    width: 280px;
    height: 330px;
    margin-left: 20px;
  }
  .project-image {
    height: 150px;
  }
}
/* 🖥️ Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .work-main-container {
    height: 1400px;
  }
}
/* 🖥️ custom to adjust projects text between 300 and 780 */
@media only screen and (min-width: 300px) and (max-width: 780px) {
  .projects-header h1 {
    font-size: 2.8rem;
  }
  .projects-container {
    margin-top: -42px;
  }
}
/* 🖥️ Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .work-main-container {
    height: 1400px;
  }
}
/* 🖥️ custom to set height of main between 875 and 992*/
@media only screen and (min-width: 875px) and (max-width: 992px) {
  .work-main-container {
    height: 1000px;
  }
}
/* 🖥️ Large devices (laptops/desktops, 992px and up)  */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .work-main-container {
    height: 1000px;
  }
}
/* 🖥️ Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .work-main-container {
    height: 1000px;
  }
}
