/* ==================================== */
/* ℹ️ main DIV container */
/* ==================================== */
.hero-main-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #000;
  font-family: "Montserrat", sans-serif;
  color: #fff;
}

/* ==================================== */
/* ℹ️ parallax container */
/* ==================================== */
.heroParallaxContainer {
  height: 60%;
  width: 800px;
  margin: 0 auto;
}

/* ==================================== */
/* ℹ️ Parallax Banner  */
/* ==================================== */
.heroPB {
  height: 100%;
}
/* ==================================== */
/* ℹ️ hero text parallax container  */
/* ==================================== */
.heroPBtextLayer {
  position: relative;
}

.heroPBtextLayer h1 {
  position: relative;
  top: 450px;
  left: 280px;
  rotate: -15deg;
  font-family: "Caveat", cursive;
  font-size: 5rem;
}

/* ==================================== */
/* ℹ️ℹ️ ---------- Media Querys ---------- ℹ️ℹ️ */
/* ==================================== */

/* 🖥️ Small devices (portrait tablets and large phones, 300px and up) */
@media only screen and (min-width: 300px) and (max-width: 600px) {
}
/* 🖥️ Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  /* hero container */
  .heroParallaxContainer {
    height: 350px;
    width: 350px;
  }
  /* hero text position and size  */
  .heroPBtextLayer h1 {
    font-size: 2rem;
    top: 160px;
    left: 100px;
  }
}
/* 🖥️ Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}
/* 🖥️ Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}
/* 🖥️ Large devices (laptops/desktops, 992px and up)  */
@media only screen and (min-width: 992px) {
}
/* 🖥️ Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
