@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Caveat:wght@700&family=Comfortaa:wght@300&family=Monoton&family=Montserrat:wght@100;200;400&family=Oxygen:wght@300&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
