/* ==================================== */
/* ℹ️ main DIV container */
/* ==================================== */
.about-main-container {
  height: 650px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  color: #000;
}

.about-header-container {
  width: 100%;
}
/* ==================================== */
/* ℹ️ topline divider containing main about heading */
/* ==================================== */
.topline {
  width: 100%;
  height: 100px;
  position: absolute;
  top: 0;
  background-color: #000;
}

.corner-divider {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fff;
  clip-path: polygon(51% 49%, 100% 0%, 100% 100%, 0% 100%);
}

/* ==================================== */
/* ℹ️ about text container */
/* ==================================== */

.about-text-container {
  width: 100%;
  height: 100%;
  color: #000;
  font-family: "Archivo Black", sans-serif;
}
/* header test  */
.about-header {
  width: 100%;
  position: absolute;
  top: 150px;
}
.about-header-text {
  font-size: 1.8rem;
  width: 80%;
  margin: 0 auto;
  border-top: 1px solid #000;
}

/* ==================================== */
/* ℹ️ subheading  */
/* ==================================== */
.about-subheader {
  width: 100%;
  position: absolute;
  top: 230px;
}
.about-subheader-text {
  font-size: 1.5rem;
  width: 80%;
  margin: 0 auto;
}
.about-header-text h1,
.about-subheader-text h1 {
  margin-left: 20px;
}

/* ==================================== */
/* ℹ️ about main body  */
/* ==================================== */
.about-body {
  color: #000;
  width: 100%;
  position: absolute;
  top: 350px;
}
.about-body-text {
  width: 80%;
  margin: 0 auto;
  font-family: "Comfortaa", cursive;
  font-weight: bold;
}
.about-body-text p {
  margin-left: 20px;
}
.about-body-text-p1 {
  font-size: 1.2rem;
  padding-left: 7%;
  word-spacing: 10px;
}
.about-body-text-p2 {
  font-size: 1.6rem;
  padding-left: 13%;
  word-spacing: 15px;
}
.about-body-text-p3 {
  font-size: 1.2rem;
  padding-left: 4%;
  word-spacing: 20px;
}
.about-body-text-p4 {
  font-size: 1.4rem;
  padding-left: 8%;
  word-spacing: 15px;
}
.about-body-text-p5 {
  font-size: 1.2rem;
  padding-left: 18%;
  word-spacing: 15px;
}

/* ==================================== */
/* ℹ️ parallax container */
/* ==================================== */
.about-parallax-container {
  height: 100%;
  width: 100%;
}

/* ==================================== */
/* ℹ️ Parallax Banner  */
/* ==================================== */
.about-parallax-banner {
  height: 100%;
  width: 100%;
  max-width: 123rem;
  margin: 0 auto;
  position: relative;
}

.about-laptop-PBL,
.about-text-PBL,
.about-cloud-PBL {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: top !important;
}

.about-left-line-divider {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  border-left: 1px solid rgba(0, 0, 0, 0.4);
}

.left-line-divider-deco {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: top !important;
}

/* ==================================== */
/* ℹ️ℹ️ ---------- Media Querys ---------- ℹ️ℹ️ */
/* ==================================== */

/* 🖥️ Small devices (portrait tablets and large phones, 300px and up) */
@media only screen and (min-width: 300px) and (max-width: 600px) {
  .about-main-container {
    height: 600px;
    overflow-x: hidden;
  }
  .corner-divider {
    right: -60px;
  }
  /* shrink the font size for the header and subheader    */
  .about-header-text {
    font-size: 0.9rem;
  }
  .about-subheader-text {
    font-size: 0.6rem;
  }
  /* hide the laptop and cloud images  */
  .about-laptop-PBL,
  .about-cloud-PBL {
    display: none;
  }
  /* shift and adjust the about paragraphs */
  .about-body {
    top: 320px;
  }
  .about-body-text-p1 {
    font-size: 1rem;
    /* padding-left: 13%; */
  }
  .about-body-text-p2 {
    padding-left: 25%;
  }
  .about-body-text-p3 {
    font-size: 0.8rem;
    padding-left: 9%;
  }
  .about-body-text-p4 {
    font-size: 0.8rem;
    padding-left: 14%;
  }
  .about-body-text-p5 {
    font-size: 0.58rem;
    padding-left: 2%;
  }
}
/* 🖥️ Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
  .about-main-container {
    height: 600px;
  }
  /* adjust the position of the subheader  */
  .about-subheader {
    top: 190px;
  }
  /* shrink the font size for the header and subheader    */
  .about-header-text {
    font-size: 1rem;
  }
  .about-subheader-text {
    font-size: 0.7rem;
  }
  /* hide the laptop and cloud images  */
  .about-laptop-PBL,
  .about-cloud-PBL {
    display: none;
  }
  /* shift and adjust the about paragraphs */
  .about-body {
    top: 300px;
  }
  .about-body-text-p1 {
    padding-left: 13%;
  }
  .about-body-text-p2 {
    padding-left: 25%;
  }
  .about-body-text-p3 {
    font-size: 0.8rem;
    padding-left: 9%;
  }
  .about-body-text-p4 {
    padding-left: 14%;
  }
  .about-body-text-p5 {
    font-size: 1.15rem;
    padding-left: 10%;
  }
}
/* 🖥️ Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .about-main-container {
    height: 600px;
  }
  /* adjust the position of the subheader */
  .about-subheader {
    top: 200px;
  }
  /* shrink the font size for the header and subheader    */
  .about-header-text {
    font-size: 1.3rem;
  }
  .about-subheader-text {
    font-size: 1rem;
  }
  /* hide the laptop and cloud images  */
  .about-laptop-PBL,
  .about-cloud-PBL {
    display: none;
  }
  /* shift and adjust the about paragraphs */
  .about-body {
    top: 320px;
  }
  .about-body-text-p1 {
    padding-left: 13%;
  }
  .about-body-text-p2 {
    padding-left: 18%;
  }
  .about-body-text-p3 {
    font-size: 1rem;
    padding-left: 9%;
  }
  .about-body-text-p4 {
    padding-left: 13%;
  }
  .about-body-text-p5 {
    padding-left: 23%;
  }
}
/* 🖥️ Large devices (laptops/desktops, 992px and up)  */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  /* shrink the size of the font for the header and subheader */
  .about-main-container {
    height: 600px;
  }
  .about-body {
    top: 280px;
  }
  .about-header-text {
    font-size: 0.9rem;
  }
  .about-subheader-text {
    font-size: 0.7rem;
  }
  /* shift the subheader up a bit */
  .about-subheader {
    top: 190px;
  }

  /* .about-body-text-p1 {
    font-size: 1.2rem;
    padding-left: 7%;
    word-spacing: 10px;
  } */
  .about-body-text-p2 {
    font-size: 1.4rem;
    /* padding-left: 13%; */
    /* word-spacing: 15px; */
  }
  .about-body-text-p3 {
    font-size: 0.85rem;
    padding-left: 1%;
    word-spacing: 10px;
  }
  .about-body-text-p4 {
    /* font-size: 1.4rem; */
    padding-left: 5%;
    /* word-spacing: 15px; */
  }
  .about-body-text-p5 {
    font-size: 1.1rem;
    padding-left: 11%;
    /* word-spacing: 15px; */
  }
}
/* 🖥️ Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-width: 1800px) {
  .about-main-container {
    height: 600px;
  }
  .about-body {
    top: 300px;
  }
  /* shrink the size of the font for the header and subheader */
  .about-header-text {
    font-size: 1.15rem;
  }
  .about-subheader-text {
    font-size: 0.9rem;
  }
  /* shift the subheader up a bit */
  .about-subheader {
    top: 200px;
  }
  /* adjust size and padding of 3rd paragraph */
  .about-body-text-p3 {
    font-size: 1rem;
    padding-left: 2%;
  }
}
